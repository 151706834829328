require('../modules/es7.reflect.define-metadata');
require('../modules/es7.reflect.delete-metadata');
require('../modules/es7.reflect.get-metadata');
require('../modules/es7.reflect.get-metadata-keys');
require('../modules/es7.reflect.get-own-metadata');
require('../modules/es7.reflect.get-own-metadata-keys');
require('../modules/es7.reflect.has-metadata');
require('../modules/es7.reflect.has-own-metadata');
require('../modules/es7.reflect.metadata');
module.exports = require('../modules/_core').Reflect;
